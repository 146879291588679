import FloatLabels from "float-labels.js";
import Sticky from "sticky-js";

document.addEventListener("DOMContentLoaded", function () {
  var floatlabels = new FloatLabels(".gform_fields", {
    style: 2,
    requiredClass: "gfield_contains_required",
  });

  jQuery(document).on(
    "gform_post_render",
    function (event, form_id, current_page) {
      var floatlabels = new FloatLabels(".gform_fields", {
        style: 2,
        requiredClass: "gfield_contains_required",
      });
    }
  );
});

//page links height
document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector(".site-main__content--links")) {
    var element = document.querySelector(".site-main__content--links");
    var bodyHeight = document.getElementById("main").scrollHeight + 400;

    element?.setAttribute("style", "--height: " + bodyHeight + "px");
  }

  if (document.querySelectorAll(".fl-input").length >= 1) {
    var inputs = document.querySelectorAll(".fl-input");

    for (var i = 0; i < inputs.length; i++) {
      if (
        !inputs[i].getAttribute("placeholder").includes("*") &&
        inputs[i].getAttribute("aria-required") == "true"
      ) {
        inputs[i].setAttribute(
          "placeholder",
          inputs[i].getAttribute("placeholder") + "*"
        );
      }
    }
  }
});

//sticky
document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector(".site-main__content--links")) {
    var sticky = new Sticky(".site-main__content--links__items");
  }
});
