document.addEventListener("DOMContentLoaded", function () {
  var acc = document.getElementsByClassName("block-faq__wrap--item__question");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function (e) {
      e.preventDefault();
      this.classList.toggle("block-faq__wrap--item__question--active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 24 + "px";
      }
    });
  }
});
