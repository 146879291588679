import Rellax from "rellax";

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("rellax").length >= 1) {
    var rellax = new Rellax(".rellax", {
      speed: -2,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
  }
});
