// Fetch data from the REST API
import MicroModal from "micromodal";
import DataTable from "datatables.net-dt";

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementById("map")) {
    fetch("/wp-json/sppoh-scholen/v1/get-data")
      .then((response) => response.json())
      .then((data) => {
        initializeMap(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // Initialize the map
    function initializeMap(data) {
      /* -------------------------------------------------------------------------- */
      /*                          Initialize table as well                          */
      /* -------------------------------------------------------------------------- */
      const htmlElement = document.documentElement;

      if (htmlElement.getAttribute("lang") == "nl-NL") {
        let table = new DataTable("#mapTable", {
          paging: false,
          retrieve: true,
          data: data,
          columns: [
            { data: "title" },
            { data: "type_label" },
            { data: "area_label" },
            { data: "btn" },
          ],
          language: {
            lengthMenu: "Toon _MENU_ scholen per pagina",
            zeroRecords: "Geen scholen gevonden",
            infoEmpty: "Geen scholen beschikbaar",
            infoFiltered: "(gefilterd van de _MAX_ totale scholen)",
            search: "",
            searchPlaceholder: "Zoeken...",
          },
        });
      } else {
        let table = new DataTable("#mapTable", {
          paging: false,
          retrieve: true,
          data: data,
          columns: [
            { data: "title" },
            { data: "type_label_en" },
            { data: "area_label" },
            { data: "btn" },
          ],
          language: {
            lengthMenu: "Toon _MENU_ scholen per pagina",
            zeroRecords: "Geen scholen gevonden",
            infoEmpty: "Geen scholen beschikbaar",
            infoFiltered: "(gefilterd van de _MAX_ totale scholen)",
            search: "",
            searchPlaceholder: "Zoeken...",
          },
        });
      }

      const map = new ol.Map({
        target: "map",
        layers: [
          new ol.layer.Tile({
            source: new ol.source.OSM(),
          }),
        ],
        view: new ol.View({
          center: ol.proj.fromLonLat([4.3503586, 52.0688278]), // Set your initial map center
          zoom: 10, // Set your initial zoom level
        }),
      });

      // Get the filter elements
      const areaCheckboxes = document.querySelectorAll(".area-checkbox");
      const typeCheckboxes = document.querySelectorAll(".type-checkbox");

      // Event listeners for checkbox changes
      areaCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener("change", updateMarkers);
      });

      typeCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener("change", updateMarkers);
      });

      // Get the search input element
      const searchInput = document.getElementById("search-input");

      // Event listener for search input
      searchInput.addEventListener("input", updateMarkers);

      function updateMarkers() {
        // const tableBody = document.querySelector(".sppoh-map__table--items");
        // const table = document.querySelector(".sppoh-map__table");
        const searchQuery = searchInput.value.toLowerCase();

        const selectedAreas = Array.from(areaCheckboxes)
          .filter((checkbox) => checkbox.checked)
          .map((checkbox) => checkbox.value);

        const selectedTypes = Array.from(typeCheckboxes)
          .filter((checkbox) => checkbox.checked)
          .map((checkbox) => checkbox.value);

        // //console.log(selectedAreas);
        // //console.log(selectedTypes);

        const parentElement = document.querySelector(
          ".sppoh-map__title--filters__active"
        );

        const areaContainer = document.querySelector(
          ".sppoh-map__title--filters__active--area"
        );
        const typeContainer = document.querySelector(
          ".sppoh-map__title--filters__active--type"
        );

        areaContainer.innerText = "";
        typeContainer.innerText = "";

        // Loop through the array and create <a> elements for each item
        selectedAreas.forEach((area) => {
          // Create a new <a> element
          const aElement = document.createElement("a");
          aElement.className = "active-area";
          aElement.href = "#";
          aElement.setAttribute("data-id", area);
          // Set the innerText of the <a> element to the current array area
          aElement.innerText = area;
          aElement.addEventListener("click", function (e) {
            e.preventDefault();
            var inputID = aElement.getAttribute("data-id");
            document.getElementById(inputID).click();
          });
          // Append the <a> element to the parent element
          areaContainer.appendChild(aElement);
        });

        selectedTypes.forEach((type) => {
          // Create a new <a> element
          const aElementSecond = document.createElement("a");
          aElementSecond.className = "active-type";
          aElementSecond.href = "#";
          aElementSecond.setAttribute("data-id", type);
          // Set the innerText of the <a> element to the current array type
          aElementSecond.innerText = type;
          var lang = document.documentElement.getAttribute("lang");
          if (lang === "nl-NL") {
            if (type === "regular-primary") {
              aElementSecond.innerText = "Regulier Basisonderwijs";
            } else if (type === "special-primary") {
              aElementSecond.innerText = "Speciaal Basisonderwijs";
            } else if (type === "special") {
              aElementSecond.innerText = "Speciaal Onderwijs";
            }
          } else {
            if (type === "regular-primary") {
              aElementSecond.innerText = "Regular Primary Education";
            } else if (type === "special-primary") {
              aElementSecond.innerText = "Special Primary Education";
            } else if (type === "special") {
              aElementSecond.innerText = "Special Education";
            }
          }

          aElementSecond.addEventListener("click", function (e) {
            e.preventDefault();
            var inputID = aElementSecond.getAttribute("data-id");
            document.getElementById(inputID).click();
          });
          // Append the <a> element to the parent element
          typeContainer.appendChild(aElementSecond);
        });

        // parentElement.appendChild(areaContainer);
        // parentElement.appendChild(typeContainer);

        vectorSource.clear(); // Clear the existing vectorSource

        /* -------------------------------------------------------------------------- */
        /*                               Filtered table                               */
        /* -------------------------------------------------------------------------- */
        const filteredMarkers = data.filter((item) => {
          return (
            (selectedAreas.length === 0 || selectedAreas.includes(item.area)) &&
            (selectedTypes.length === 0 || selectedTypes.includes(item.type)) &&
            item.title.toLowerCase().includes(searchQuery)
          );
        });
        new DataTable("#mapTable").destroy();
        //console.log(filteredMarkers.length);
        if (selectedAreas.length >= 1 || selectedTypes.length >= 1) {
          let table = new DataTable("#mapTable", {
            // ajax: {
            //   url: "/wp-admin/admin-ajax.php?action=sppoh_downloads_api",
            // },
            data: filteredMarkers,
            paging: false,
            retrieve: true,
            columns: [
              { data: "title" },
              { data: "type_label" },
              { data: "area_label" },
              { data: "btn" },
            ],
            language: {
              lengthMenu: "Toon _MENU_ scholen per pagina",
              zeroRecords: "Geen scholen gevonden",
              infoEmpty: "Geen scholen beschikbaar",
              infoFiltered: "(gefilterd van de _MAX_ totale scholen)",
              search: "",
              searchPlaceholder: "Zoeken...",
            },
          });
        } else {
          let table = new DataTable("#mapTable", {
            paging: false,
            retrieve: true,
            data: data,
            columns: [
              { data: "title" },
              { data: "type_label" },
              { data: "area_label" },
              { data: "btn" },
            ],
            language: {
              lengthMenu: "Toon _MENU_ scholen per pagina",
              zeroRecords: "Geen scholen gevonden",
              infoEmpty: "Geen scholen beschikbaar",
              infoFiltered: "(gefilterd van de _MAX_ totale scholen)",
              search: "",
              searchPlaceholder: "Zoeken...",
            },
          });
        }

        const markers = filteredMarkers.map((item) => {
          const marker = new ol.Feature({
            geometry: new ol.geom.Point(
              ol.proj.fromLonLat([item.longitude, item.latitude])
            ),
          });

          marker.setProperties(item); // Attach all properties to the marker
          // table.classList.remove("sppoh-map__table--hidden");
          const row = document.createElement("a");

          return marker;
        });

        // if (
        //   selectedAreas.length === 0 &&
        //   selectedTypes.length === 0 &&
        //   searchQuery === "" &&
        // ) {
        //   // Hide the table container when no filters and no search query
        //   table.classList.add("sppoh-map__table--hidden");
        // }

        vectorSource.addFeatures(markers);
      }
      // Create markers from the fetched data
      const markers = data.map((item) => {
        const marker = new ol.Feature({
          geometry: new ol.geom.Point(
            ol.proj.fromLonLat([item.longitude, item.latitude])
          ),
        });

        marker.setProperties(item); // Attach all properties to the marker

        return marker;
      });

      // Create a vector layer for markers
      const vectorSource = new ol.source.Vector({
        features: markers,
      });

      var icon = "";
      if (window.location.href.includes("ouders.sppoh")) {
        icon = "/wp-content/themes/sppoh-ouders-professionals-wordpress/images/svg/location-small-orange.svg";
      } else {
        icon = "/wp-content/themes/sppoh-ouders-professionals-wordpress/images/svg/location-small.svg";
      }

      const vectorLayer = new ol.layer.Vector({
        source: vectorSource,
        style: new ol.style.Style({
          image: new ol.style.Icon({
            src: icon,
            scale: 0.8,
            opacity: 1,
          }),
          className: "ol-pointer",
        }),
      });

      map.addLayer(vectorLayer);

      /* -------------------------------------------------------------------------- */
      /*                                 Show modal                                 */
      /* -------------------------------------------------------------------------- */
      // Handle marker click events
      map.on("click", (event) => {
        map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
          const properties = feature.getProperties();
          // Handle click event and display details
          // Update the popup content with marker details
          const popupContent = `
      <div>
      <ul>
      <li>${properties.address}</li>
      <li>${properties.postcode} ${properties.city}</li>
      </ul>
      
      </div>
    `;

          const mapsUrl =
            "https://www.google.com/maps/?saddr=My+Location&daddr= " +
            encodeURIComponent(properties.address + " " + properties.postcode);

          MicroModal.show("modal-1"); // [1]
          document.getElementById("modal-1-title").innerText = properties.title;
          document.getElementById("modal-email").innerText = properties.email;
          document.getElementById("modal-email").href =
            "mailto:" + properties.email;
          document.getElementById("modal-phone").innerText = properties.phone;
          document.getElementById("modal-phone").href =
            "tel:" + properties.phone;
          document.getElementById("modal-1-title").innerText = properties.title;
          if (document.documentElement.getAttribute("lang") == "nl-NL") {
            document.getElementById("modal-subtitle").innerText =
              properties.type_label;
            document.getElementById("modal-btn").href = properties.link;
          } else {
            document.getElementById("modal-subtitle").innerText =
              properties.type_label_en;
            document.getElementById("modal-btn").href = properties.link.replace(
              ".nl/",
              ".nl/en/"
            );
          }

          document.getElementById("popup-content").innerHTML = popupContent;
          document.getElementById("route-btn").href = mapsUrl;
        });
      });

      /* -------------------------------------------------------------------------- */
      /*                            Set pointer on hover                            */
      /* -------------------------------------------------------------------------- */
      map.on("pointermove", function (evt) {
        var hit = this.forEachFeatureAtPixel(
          evt.pixel,
          function (feature, layer) {
            return true;
          }
        );
        if (hit) {
          this.getTargetElement().style.cursor = "pointer";
        } else {
          this.getTargetElement().style.cursor = "";
        }
      });
    }

    /* -------------------------------------------------------------------------- */
    /*                      Switch between map and table view                     */
    /* -------------------------------------------------------------------------- */
    var mapView = document.getElementById("mapView");
    var tableView = document.getElementById("tableView");

    tableView.addEventListener("click", function (e) {
      e.preventDefault();

      if (mapView.classList.contains("active")) {
        mapView.classList.remove("active");
        this.classList.add("active");
        document.querySelector(".sppoh-map__map--table").style.display =
          "block";
        document.getElementById("map").style.display = "none";
      }
    });

    mapView.addEventListener("click", function (e) {
      e.preventDefault();

      if (tableView.classList.contains("active")) {
        tableView.classList.remove("active");
        this.classList.add("active");
        document.querySelector(".sppoh-map__map--table").style.display = "none";
        document.getElementById("map").style.display = "block";
      }
    });
  }
});

window.addEventListener("load", function () {
  const urlParams = new URLSearchParams(window.location.search);
  const areaQuery = urlParams.get("area");
  const typeQuery = urlParams.get("type");

  if (areaQuery) {
    this.document.getElementById(areaQuery).click();
  }

  if (typeQuery) {
    this.document.getElementById(typeQuery).click();
  }
});
