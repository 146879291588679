document.addEventListener("DOMContentLoaded", function () {
  jQuery(function ($) {
    var searchField = document.querySelectorAll(".search-form .search-field");

    $(".search-field").on("input", function (e) {
      e.preventDefault();
      var $inputField = $(this);
      if ($inputField.val().length >= 3) {
        $inputField.parent().parent().addClass("search-active");
        $.ajax({
          type: "POST",
          url: "/wp-admin/admin-ajax.php",
          dataType: "html",
          data: {
            action: "sppoh_search",
            query: $inputField.val(),
          },
          beforeSend: function () {
            //console.log("loading");
            $inputField
              .parent()
              .next()
              .next()
              .addClass("search-form__results--loading");
          },
          success: function (res) {
            $inputField.parent().next().next().html(res);
            $inputField
              .parent()
              .next()
              .next()
              .removeClass("search-form__results--loading");
            //console.log(res);
          },
        });
      } else if ($inputField.val().length <= 0) {
        if ($inputField.parent().parent().hasClass("search-active")) {
          $inputField.parent().parent().removeClass("search-active");
        }
        $inputField.parent().next().next().empty();
        if (
          $inputField
            .parent()
            .next()
            .next()
            .hasClass(".search-form__results--loading")
        ) {
          $inputField
            .parent()
            .next()
            .next()
            .removeClass(".search-form__results--loading");
        }
      }
    });

    $(".search-field").on("focusout", function (e) {
      var $this = $(this);

      //mobile parent

      var $parent = $this.parent().parent().parent();
      setTimeout(function () {
        if ($this.parent().parent().hasClass("search-active")) {
          $this.parent().parent().removeClass("search-active");
        }

        if (
          $parent.hasClass("site-header__nav--mobile__menu--actions__form") &&
          $parent.hasClass(
            "site-header__nav--mobile__menu--actions__form--active"
          )
        ) {
          $parent.removeClass(
            "site-header__nav--mobile__menu--actions__form--active"
          );
        }
      }, 300);
    });

    $(".search-field").on("focus", function (e) {
      var $this = $(this);
      var $parent = $this.parent().parent().parent();
      if (
        $parent.hasClass("site-header__nav--mobile__menu--actions__form") &&
        !$parent.hasClass(
          "site-header__nav--mobile__menu--actions__form--active"
        )
      ) {
        $parent.addClass(
          "site-header__nav--mobile__menu--actions__form--active"
        );
      }
    });
  });
});
