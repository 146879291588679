document.addEventListener("DOMContentLoaded", function () {
  if (
    document.getElementsByClassName("site-main__feedback--wrap__btns--btn")
      .length >= 1
  ) {
    var btn = document.getElementsByClassName(
      "site-main__feedback--wrap__btns--btn"
    );
    var wrapTitle: HTMLElement = document.querySelector(
      ".site-main__feedback--wrap h3"
    )!;
    var form = document.querySelector(".site-main__feedback--wrap__form");
    var input: HTMLInputElement = document.getElementById("input_1_1");
    var wrap: HTMLElement = document.querySelector(
      ".site-main__feedback--wrap"
    );

    for (var i: number = 0; i < btn.length; i++) {
      btn[i].addEventListener("click", function (e) {
        e.preventDefault();
        wrapTitle.style.opacity = "0";
        form?.classList.add("site-main__feedback--wrap__form--active")!;
        input.value = this.getAttribute("data-type");
        wrap.style.height = wrap?.scrollHeight + 50 + "px";
      });
    }
  }
});
