
const observerOptions = {
  root: null,
  threshold: 1,
};

document.addEventListener("DOMContentLoaded", function () {
  jQuery(function ($) {
    var btn = document.getElementById("loadMoreEvents");
    var wrapper = document.querySelector(
      ".sppoh-visual-block__content--events"
    );
    if (btn) {
      btn.addEventListener("click", function (e) {
        e.preventDefault();
        var wrapper = document.querySelector(
          ".sppoh-visual-block__content--events"
        );
        var count = parseInt(wrapper.getAttribute("data-index"));
        console.log('month: ' + count);
        var year = parseInt(wrapper.getAttribute("data-year"));
        console.log('year: ' + year);

        var $trigger = $(".sppoh-event-listing__trigger");

        $.ajax({
          type: "POST",
          url: "/wp-admin/admin-ajax.php",
          dataType: "html",
          data: {
            action: "sppoh_infinite_scroll_events",
            index: count+1,
            year: year,
          },
          beforeSend: function () {
            wrapper.classList.add(
              "sppoh-visual-block__content--events__loading"
            );
            $(".sppoh-event-listing__trigger").addClass(
              "sppoh-event-listing__trigger--loading"
            );
            $trigger.addClass("sppoh-event-listing__trigger--loading");
          },
          success: function (res) {
            $(".sppoh-event-listing__more").append(res);

            if (count + 3 > 12) {
              wrapper.setAttribute("data-index", count + 3 - 12);
              wrapper.setAttribute("data-year", year + 1);
            } else {
              wrapper.setAttribute("data-index", count + 3);
            }
            $(".sppoh-event-listing__trigger").removeClass(
              "sppoh-event-listing__trigger--loading"
            );
            wrapper.classList.remove(
              "sppoh-visual-block__content--events__loading"
            );
          },
        });
      });
    }
  });
});