import DataTable from "datatables.net-dt";

document.addEventListener("DOMContentLoaded", function () {
  const htmlElement = document.documentElement;

  if (htmlElement.getAttribute("lang") == "nl-NL") {
    let table = new DataTable("#downloadsTable", {
      ajax: {
        url: "/wp-admin/admin-ajax.php?action=sppoh_downloads_api",
      },
      paging: true,
      columns: [
        { data: "title" },
        { data: "categories" },
        { data: "date" },
        { data: "file_url" },
      ],
      language: {
        lengthMenu: "Toon _MENU_ documenten per pagina",
        zeroRecords: "Geen documenten gevonden",
        infoEmpty: "Geen documenten beschikbaar",
        infoFiltered: "(gefilterd van de _MAX_ totale downloads)",
        search: "",
        searchPlaceholder: "Zoeken...",
        paginate: {
          first: "Eerste",
          last: "Laatste",
          next: "Volgende",
          previous: "Vorige",
        },
      },
    });
  } else {
    let table = new DataTable("#downloadsTable", {
      ajax: {
        url: "/wp-admin/admin-ajax.php?action=sppoh_downloads_api",
      },
      columns: [
        { data: "title" },
        { data: "categories" },
        { data: "date" },
        { data: "file_url" },
      ],
      paging: true,
      language: {
        lengthMenu: "Show _MENU_ documents per page",
        zeroRecords: "No documents found",
        infoEmpty: "No documents available",
        infoFiltered: "(Filtered from _MAX_ total downloads)",
        search: "",
        searchPlaceholder: "Search...",
      },
    });
  }
});
